import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { IntlProvider } from 'react-intl'

// load our locales
import messages from './messages'

const LOCAL_STORAGE_LANGUAGE = 'rsk-web.language'

export const I18nContext = React.createContext({
  locale: 'en-US',
  onLocaleChange: () => {},
})

class I18nProvider extends Component {
  state = {
    locale: 'en-US',
    // eslint-disable-next-line react/no-unused-state
    onLocaleChange: this.handleLocaleChange,
  }

  componentDidMount() {
    const { locale } = this.state

    this.setState({
      locale: window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || locale,
    })
  }

  handleLocaleChange = (locale) => {
    window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE, locale)
    moment.locale(locale)

    this.setState({ locale })
  }

  render() {
    const { children } = this.props
    const { locale } = this.state

    return (
      <I18nContext.Provider value={this.state}>
        <IntlProvider
          locale={locale.split('-')[0]}
          messages={messages[locale]}
        >
          {children}
        </IntlProvider>
      </I18nContext.Provider>
    )
  }
}

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default I18nProvider
